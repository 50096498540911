import format from 'date-fns/format'
import { Observable } from 'rxjs'
import { NgxSpinnerService } from 'ngx-spinner'
import { Injectable } from '@angular/core'
import { TranslocoService } from "@ngneat/transloco"
import { first } from "rxjs/operators"
import { ApiService } from './api.service'
import { AuthService } from './auth.service'
import { ICartItem, ITransaction, TransactionType } from './cart.interface'
import { Cry } from './cry.service'
import { EmailService } from './email.service'
import { OrderStatus, PrimaryCode } from './enums'
import { FirestoreService2 } from './firestore.service2'
import { FnacService } from './fnac.service'
import { GlobalService } from './global.service'
import { NotifyService } from './notifications/notify.service'
import { IPaymentBase2, PaymentStatus } from './payment-gateway/payment.interface'
import { use } from './utils'

@Injectable({ providedIn: "root" })
export class CartService {

  apiUrl: any
  private fss: FirestoreService2

  constructor(
    public notify: NotifyService,
    private spin: NgxSpinnerService,
    private auth: AuthService,
    public g: GlobalService,
    private fnac: FnacService,
    private transloco: TranslocoService,
  ) {
    console.info(`## ${this.constructor.name}`)
    this.apiUrl = this.g.get('apiUrl')
  }

  modify(what: string, product: any, user: any) {
    if (!product.id && product.objectID) product.id = product.objectID
    const { isSame, index } = this.productIsSame(product, user)
    if (what === 'cart') user.pointsCart = []
    if (index > -1 && isSame) {
      this.updateLine(what, 'plus', index, user)
    } else {
      this.add(what, product, user)
    }
  }


  /**
   * Verifica a existência do produto já existir com as mesmas caracteristicas no cart do utilizador
   * Objects mesmo iguals não resultam em TRUE se comparados. Uma alternativa para compará-los
   * e não usar uma biblioteca externa (lodash) é comparar com JSON.stringify, e corre bem
   * @param product
   * @param user
   */
  private productIsSame(product: any, user: any): { isSame: boolean, index: number } {
    let isSame = false
    let index = -1
    if (user && user.cart && user.cart instanceof Array) {
      for (const [ idx, prod ] of user.cart.entries()) {
        if (product.variants && prod.variants) {
          if (JSON.stringify(product.variants) === JSON.stringify(prod.variants)) {
            isSame = true
            index = idx
          }
        } else if (product.id === prod.id) {
          isSame = true
          index = idx
        }
      }
    }
    return { isSame, index }
  }

  add(what: string, product: any, user: any, cartLine?: any, cardIdTemp?: string) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    if (!product.isVoucher && this.g.get('selectedStore') && !this.g.get('selectedStore').userSelect) {
      this.g.set('openPostCodeModal', true)
    }
    if (!user.cart || !user.cart.length) {
      this.g.set('openPostCodeModal', true)
    }

    const cardLineFreezed = Object.freeze(cartLine)
    const line = {
      // id: product.id,
      // name: product.name,
      // category: product.category,
      // subcategory: product.subcategory,
      // seller: product.seller,
      // sellerUrl: product.sellerUrl,
      // billable: product.billable,
      // image: product.image
      ...product,
      qty: 1,
      price: parseFloat(product.price),
      vatRate: parseFloat(product.vatRate),
      points: parseFloat(product.points),
      amtPoints: parseFloat(product.amtPoints),
      // variants: product.variants
    }
    if (cartLine) {
      line.points = parseFloat(cardLineFreezed.amtPoints)
      line.amtPoints = parseFloat(cardLineFreezed.amtPoints)
      line.price = line.points
      line.vatRate = parseFloat(cardLineFreezed.vatRate)
      line.billable = cardLineFreezed.billable
      line[ 'rechargeable' ] = product.rechargeable
      line[ 'seller' ] = product.seller

      if (product.variablePrice || product.fixedPrices) {
        line.price = Math.ceil(line.price * this.g.program.cbXrate)
      }
      if (cardLineFreezed.cardId) {
        line[ 'cardId' ] = cardLineFreezed.cardId
      }

      if (cardIdTemp) line[ 'cardIdTemp' ] = cardIdTemp
    }

    if (!line[ 'cardId' ]) line[ 'cardId' ] = ''
    if (!line[ 'cardIdTemp' ]) line[ 'cardIdTemp' ] = ''

    if (!user[ what ]) user[ what ] = []
    if (what === 'wishlist' && user[ what ].find(i => i.id === line.id)) user[ what ].splice(user[ what ].findIndex(i => i.id === line.id), 1)
    else user[ what ].push({ ...line, addedInCart: new Date().toISOString() })
    user = this.calcUserTotals(user)


    this.fss.update(`users`, user.id, { ...user }, { silent: true })
    // this.api.post('db/action', Cry.crypt(JSON.stringify({
    //   action: 'update',
    //   collection: 'users',
    //   id: user.id,
    //   nick: this.g.nick,
    //   email: user.email,
    //   data: { ...user }
    // }), true)).toPromise().then()
  }

  async updateLine(what: string, sign: string, i: number, user: any) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    if (what === 'cart') user = this.changeQtiesCart(sign, i, user)
    if (what === 'wishlist') user = this.changeQtiesWish(sign, i, user)
    user = this.calcUserTotals(user)
    this.spin.show()
    await this.fss.update(`users`, user.id, user, { silent: true }).then(() => this.spin.hide()).catch(() => this.spin.hide())
    // await this.api.post('db/action', Cry.crypt(JSON.stringify({
    //   action: 'update',
    //   collection: 'users',
    //   id: user.id,
    //   nick: this.g.nick,
    //   email: user.email,
    //   data: { ...user }
    // }), true)).toPromise().then(() => this.spin.hide()).catch(() => this.spin.hide())
  }

  changeQtiesCart(sign: string, index: number, user: any) {
    if (user && user.cart && user.cart[ index ]) {
      user.wishlist = user.wishlist || []
      if (sign === 'remove') {
        user.cart.splice(index, 1)
      } else if (sign === 'plus' && user.cart[ index ].rechargeable) {
        this.notify.update('Apenas é possivel encomendar/carregar um cartão por linha', 'btn-danger', 10000)
      } else if (sign === 'plus') {
        user.cart[ index ].qty++
        // if (user.cart[index].name === 'YES CARD') user.cartId = user.email // TODO
      } else {
        user.cart[ index ].qty--
        if (user.cart[ index ].qty === 0) {
          const cardIdTemp = user.cart[ index ].cardIdTemp
          if (cardIdTemp) {
            user.cart = [ ...user.cart.filter(item => item.cardIdTemp !== cardIdTemp) ]
          } else {
            user.wishlist.push({ ...user.cart[ index ] })
            user.cart.splice(index, 1)
          }
        }
      }
    }

    /**
     * Sempre que um item tem seu valor alterado no cart, obrigatóriamente deve ser
     * zerado o pointsCart, para que uma nova compra de pontos recalculados seja
     * possível
     */
    user.pointsCart = []

    return { ...user }
  }

  changeQtiesWish(sign: string, index: number, user: any) {
    if (user && sign === 'minus') {
      if (sign === 'minus') {
        if (user.wishlist && user.wishlist[ index ]) {
          user.wishlist[ index ].qty--
          if (user.wishlist[ index ].qty <= 0) user.wishlist.splice(index, 1)
        }
      }
    }
    return { ...user }
  }

  calcUserTotals(user: any) {
    user.orderQty = 0
    user.wishQty = 0
    user.orderPoints = 0
    user.orderEuros = 0
    user.merchandisingPoints = 0
    user.cart = user.cart || []
    user.wishlist = user.wishlist || []
    user.pointsCart = user.pointsCart || []

    if (user.cart && user.cart instanceof Array) {
      for (const [ index, cartItem ] of user.cart.entries()) {
        cartItem.idx = index
        cartItem[ 'points' ] = parseFloat(cartItem[ 'points' ])
        cartItem[ 'vatRate' ] = parseFloat(cartItem[ 'vatRate' ])
        // cartItem['billable'] = cartItem['billable']

        if (cartItem.id === 'buy-point') {
          cartItem.plus = '<span class="btn btn-circle btn-buy text-danger"><i class="fas fa-trash"></i></span>'
          cartItem.minus = ''
          cartItem.nameLink = cartItem.name
        } else if (cartItem[ 'name' ].indexOf('emissão') > -1/* && !this.g.program.options.chargeCardsToUsers*/) {
          cartItem.plus = ''
          cartItem.minus = ''
          cartItem.nameLink = cartItem.name
        } else {
          cartItem[ 'amtEuros' ] = cartItem[ 'qty' ] * cartItem[ 'price' ]
          cartItem[ 'amtPoints' ] = cartItem[ 'qty' ] * cartItem[ 'points' ]
          cartItem.plus = '<span class="btn btn-circle"><i class="fas fa-plus"></i></span>'
          cartItem.minus = '<span class="btn btn-circle"><i class="fas fa-minus"></i></span>'
          cartItem.nameLink = `<button class="btn btn-sm btn-link m-0 p-0">${cartItem.name}<i class="fas fa-link"></i></button>${!cartItem.stock ? ' <span class="badge badge-danger">indisponível</span>' : ''}`
          cartItem.nameMsg = `${cartItem.name}${!cartItem.stock ? ' <span class="badge badge-danger">indisponível</span>' : ''}`
        }

        user.orderQty += cartItem.qty
        user.orderPoints += cartItem.amtPoints
        user.orderEuros += cartItem.amtEuros
        if (cartItem[ 'name' ].indexOf(' - NOS') !== -1) user.merchandisingPoints += cartItem[ 'amtPoints' ]
      }
    }

    if (user.wishlist && user.wishlist instanceof Array) {
      for (const [ index, whishItem ] of user.wishlist.entries()) {
        whishItem.idx = index
        whishItem[ 'points' ] = parseFloat(whishItem[ 'points' ])
        whishItem[ 'vatRate' ] = parseFloat(whishItem[ 'vatRate' ])
        whishItem[ 'billable' ] = whishItem[ 'billable' ]
        if (whishItem[ 'name' ].indexOf('emissão') >= 0 && !this.g.program.options.chargeCardsToUsers) {
          whishItem[ 'amtEuros' ] = 0
          whishItem[ 'amtPoints' ] = 0
        } else {
          whishItem[ 'amtEuros' ] = whishItem[ 'qty' ] * whishItem[ 'price' ]
          whishItem[ 'amtPoints' ] = whishItem[ 'qty' ] * whishItem[ 'points' ]
          whishItem.plus = '<span class="btn btn-circle"><i class="fas fa-shopping-cart"></i></span>'
          whishItem.minus = '<span class="btn btn-circle"><i class="fas fa-trash"></i></span>'
          whishItem.nameLink = '<button class="btn btn-sm btn-link m-0 p-0">' + whishItem.name + '<i class="fas fa-link"></i></button>'
        }

        user.wishQty += whishItem.qty
      }
    }

    this.spin.hide()
    return user
  }

  async updateCart(user) {
    this.spin.show()

    /**
     * Este TRY trata o caso da API SCRAP falhar em algum momento.
     * Caso falhe, assumimos que, por segurança, os produtos não-recharcheable
     * Estão temporáriamente fora de stock
     */
    if (user.cart && user.cart instanceof Array) {
      try {
        await this.updateAllLines(user)
      } catch (e) {
        for (const item of user.cart) {
          if (!item.rechargeable) {
            item.stock = false
          }
        }
      }
      // await this.fss.update('users', user.id, { createdAt: user.createdAt, cart: user.cart }, { silent: true }).then()
      const api = use<ApiService>(ApiService)
      await api.post('db/action', Cry.crypt(JSON.stringify({
        action: 'update',
        collection: 'users',
        id: user.id,
        nick: this.g.nick,
        email: user.email,
        data: { createdAt: user.createdAt, cart: user.cart }
      }), true)).toPromise().then()
      return this.calcUserTotals(user)
    }
  }

  async updateAllLines(user: any) {
    const results = []
    for (const line of user.cart) {
      if (line.rechargeable) {
        // if (!line.cardId) line.cardId = line.cardIdTemp // TODO ???
      } else line.cardId = ''
      const fnac = line.seller === 'FNAC' || line.seller === 'ODISSEIAS'
      results.push(this.updateCartLine(line, this.g.nick, user, fnac))
    }
    return await Promise.all(results)
  }

  async updateCartLine(line: any, nick: string, user: any, fnac: boolean) {
    // A LINHA ABAIXO É NECESSÁRIA PARA PULAR O SCRAP TEMPORÁRIAMENTE ATÉ QUE SE POSSA FAZE-LO NOCAMENTE VIA FUNCTIONS
    return line

    if (fnac === true) {
      // const product = await this.scrap.scrapProduct({ sellerCode: line.id, sellerUrl: line.sellerUrl })
      const product = await this.fnac.updateProduct(line)

      if (product/* && product['update'] === true*/) {
        line[ 'stock' ] = product[ 'stock' ]
        line[ 'price' ] = parseFloat(product[ 'price' ])
        line[ 'points' ] = Math.ceil(parseFloat(product[ 'price' ]) / this.g.program.cbXrate)
        // const d = new Date()
        // line['updatedAt'] = d.toISOString()
        // line['updatedBy'] = 'FNAC'
        // line['deliveryPeriod'] = 15
        // line['billable'] = product['billable']
        // line['vatRate'] = parseFloat(product['vatRate'])
        // line['points'] = Math.round((parseFloat(product['price']) / this.g.program.cbXrate + .49))
        // line['mrp'] = parseFloat(product['mrp'])
        if (line[ 'stock' ] === true) line[ 'nameMsg' ] = line[ 'name' ]
        else line[ 'nameMsg' ] = line[ 'name' ] + '<span class="badge btn-danger"><i class="fas fa-times">&nbsp;indisponível</i></span>'
      }
    } else {
      line[ 'nameMsg' ] = line[ 'name' ]
      line[ 'stock' ] = true
    }
    line[ 'points' ] = parseFloat(line[ 'points' ])
    line[ 'vatRate' ] = parseFloat(line[ 'vatRate' ])
    line[ 'amtEuros' ] = +line[ 'qty' ] * +line[ 'price' ]
    line[ 'amtPoints' ] = +line[ 'qty' ] * +line[ 'points' ]
    return line
  }

  purgeCart(cart: any[]) {
    if (cart instanceof Array && cart.length) {
      for (const line of cart) {
        delete line.idx
        delete line.plus
        delete line.minus
        line.status = OrderStatus.PLACED
      }
    }
    return [ ...cart ]
  }

  confirmReceiveCard(card: any, cardId: string, order: any, user: any = null): Observable<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    const api = use<ApiService>(ApiService)

    return new Observable<any>(obs => {
      const valid = true

      if (valid) {
        this.updateCardDetails(card, cardId, order)

        api.post('db/action', {
          action: 'list',
          collection: 'cards',
          where: [ [ 'active', '==', true ], [ 'cardId', '==', cardId ] ]
        }, true).pipe(first()).toPromise().then(existingCards => {
          if (existingCards && existingCards.length) {
            // console.log(existingCards)
            const existCard = existingCards.find(x => x.cardId === cardId)
            if (existCard.email.toLowerCase() !== user.email.toLowerCase()) {
              obs.error('Este cartão já se encontra registado para outro utilizador. Por favor indique outro número de cartão.')
              obs.complete()
            } else if (existCard.received) {
              obs.error('Este cartão já foi recebido anteriormente, por favor indique o novo numero de cartão.')
              obs.complete()
            } else {
              this.createCard(api, card, cardId, user, existCard).then(() => {
                this.updateTransaction(order, card, cardId, obs, user)
              })
            }
          } else if (this.g.program.options.allowExternalCards) {
            this.createCard(api, card, cardId, user).then(() => {
              this.updateTransaction(order, card, cardId, obs, user)
            })
          } else {
            obs.error(`Este cartão não foi emitido pela plataforma ${this.g.program.name}`)
            obs.complete()
          }
        })
      } else {
        obs.error('Nº de série inválido')
        obs.complete()
      }
    })
  }

  private updateCardDetails(card: any, cardId: string, order: any) {
    card.cardId = cardId
    const cart = order[ card.orderNo ].cart
    const index = cart.findIndex(i => i.cardIdTemp === card.cardIdTemp && i.name.toLowerCase().indexOf('emissão') === -1)
    cart[ index ].cardId = cardId.toString()
    cart[ index ].cardReceivedIn = format(new Date(), 'yyyy-MM-dd HH:mm')

    const secondLine = cart.findIndex(i => i.cardIdTemp === cart[ index ].cardIdTemp && !i.cardId)
    if (secondLine >= 0) {
      cart[ secondLine ].cardId = cardId.toString()
      cart[ secondLine ].cardReceivedIn = cart[ index ].cardReceivedIn
    }
  }

  private createCard(api: ApiService, card: any, cardId: string, user: any, existCard?: any): Promise<void> {
    return api.post('db/action', {
      action: 'set',
      collection: 'cards',
      nick: this.g.nick,
      id: `${cardId}`.trim(),
      email: this.g.user.email,
      data: {
        active: true,
        cardId: `${cardId}`.trim(),
        createdAt: existCard.createdAt || new Date().toISOString(),
        displayName: user.displayName || 'user',
        email: user.email,
        expirationDate: card.expirationDate,
        id: `${cardId}`.trim(),
        nick: this.g.nick,
        received: card.received,
        receivedIn: new Date().toISOString(),
        seller: card.seller,
      }
    }, true).pipe(first()).toPromise().then(() => console.log('saved'))
  }

  private updateTransaction(order: any, card: any, cardId: string, obs: any, user: any) {
    order[ card.orderNo ].historic = order[ card.orderNo ].historic || []
    order[ card.orderNo ].historic.push({
      createdAt: new Date().toISOString(),
      timestamp: new Date().getTime(),
      updatedBy: this.g.user.email,
      action: `Adicionou o nº de cartão <strong>${cardId}</strong>`
    })

    this.fss.update(`transactions`, card.orderNo, {
      createdAt: order.createdAt,
      cart: order[ card.orderNo ].cart,
      historic: order[ card.orderNo ].historic
    }).then(res => {
      const emailSrv = use<EmailService>(EmailService)
      this.notify.update(`Encomenda registada. Estimamos que o cartão nº ${cardId} vá ser carregado nos próximos 6 a 7 dias úteis.`, 'btn-success', 10000)
      emailSrv.sendConfirmationCardLoadEmail(card, user).subscribe(() => obs.next({ action: 'email' }), merr => console.log(merr))
    }).catch(error => {
      obs.error(error)
      obs.complete()
    })
  }


  sendConfirmationCardLoadEmail(card, cardId, user = null): Observable<any> {
    const _program = {
      name: this.g.program.name,
      sender: this.g.program.sender,
      email: this.g.program.email,
      url: this.g.program.url,
      subject: `Registo do carregamento do ${card.name} nº ${cardId}`,
      html: `O carregamento do ${card.name} nº ${cardId} ficou registado na plataforma ${this.g.program.name} e no prazo de 10 dias úteis o carregamento será efectuado.<br><br>
      <b>Muito importante:</b>
      <br>Por favor confirma o nº série do cartão e caso este não coincida com o cartão que tens em teu poder, liga-nos para o  21 406 76 94<br><br>
      Caso tenhas alguma dúvida, contacta o <b>serviço de apoio</b>.<br>
      (Disponível de segunda a sexta das 9h30 às 13:00 e das 14:00 às 18h00)<br>`,
      imgHeader: this.g.program.images[ 'emailHeader' ],
      imgFooter: this.g.program.images[ 'emailFooter' ],
      templateId: this.g.program.emails[ 'generic' ]
    }

    const _item = {
      displayName: user !== null ? user.displayName : this.g.user.displayName,
      email: user !== null ? user.email : this.g.user.email,
    }

    const emailSrv = use<EmailService>(EmailService)
    return emailSrv.sendOne(_item, _program)
  }

  validateCardCartLine(product: any, cartLine: any, purchaseType: string): Promise<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    return new Promise<any>(async (resolve, reject) => {
      if (purchaseType === 'LOAD' && product.seller === 'SIERRA') {

        const theCard = await this.fss.list('cards', { where: [ [ 'cardId', '==', cartLine.cardId ] ] }).pipe(first()).toPromise()

        if (theCard && theCard instanceof Array && theCard.length) {
          resolve(true)
        } else if (!theCard.length || theCard.length === 0) {
          reject('Não é possível carregar esse cartão. Por favor peça um novo cartão.')
        }

      } else resolve(true)
    })
  }

  addCardToCart(product: any, cartLine: any, user: any, purchaseType: string): Promise<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    return new Promise<any>(async (resolve, reject) => {
      try {
        await this.validateCardCartLine(product, cartLine, purchaseType)
      } catch (e) {
        return reject(e)
      }

      if (product.variablePrice || product.fixedPrices) {
        cartLine.qty = 1

        if (purchaseType) cartLine.op = purchaseType
        cartLine.points = +cartLine.amtPoints
        // cartLine.price = +cartLine.amtEuros

        if (purchaseType === 'NEW') {
          this.spin.show()
          const cardIdTemp = this.fss.createId

          cartLine.amtPoints = this.g.program.options.chargeCardsToUsers ? parseFloat(cartLine.amtPoints) - product.fee : parseFloat(cartLine.points)
          cartLine.points = this.g.program.options.chargeCardsToUsers ? parseFloat(cartLine.points) - product.fee : parseFloat(cartLine.points)

          await this.add('cart', { ...product, name: `${product.name} - emissão` }, user,
            {
              qty: 1,
              points: this.g.program.options.chargeCardsToUsers ? product.fee : 0,
              amtPoints: this.g.program.options.chargeCardsToUsers ? product.fee : 0,
              chargeCardsToUsers: this.g.program.options.chargeCardsToUsers || true,
              nonBillable: !this.g.program.options.chargeCardsToUsers || false
            }, cardIdTemp)

          setTimeout(() => {
            this.add('cart', product, user, cartLine, cardIdTemp)
            this.spin.hide()
          }, 500)
          resolve(true)
        } else {
          if (cartLine.cardId) user.cardId = cartLine.cardId || ''
          await this.add('cart', product, user, cartLine, cartLine.cardId || '')
          resolve(true)
        }
      } else reject('Este item não é recarregavel')
    })
  }

  /**
   * Verifica se um utilizador tem os pontos suficientes para concluir a compra do cart atual
   * UPDATE: Esta é a versão anterior onde a compra de pontos era listada como uma linha do cart
   * @param user
   */
  hasSufficientPoints2(user: any): boolean {
    if (user && user.cart && user.cart.length) {
      const payPoints = user.cart.filter(i => i.id === 'buy-point').length ? user.cart.filter(i => i.id === 'buy-point')[ 0 ].qty : 0
      const total = user.cart.filter(i => i.id !== 'buy-point').map(i => i.amtPoints).reduce((acc, val) => acc + val, 0)
      return +user.balance >= (total - payPoints)
    }
    return false
  }

  /**
   * Verifica se um utilizador tem os pontos suficientes para concluir a compra do cart atual
   * @param user
   */
  hasSufficientPoints(user: any): boolean {
    if (user && user.cart && user.cart.length) {
      user.pointsCart = user.pointsCart || []
      const payPoints = user.pointsCart.length ? user.pointsCart.map(i => i.qty).reduce((acc, val) => acc + val, 0) : 0
      const total = user.cart.map(i => i.amtPoints).reduce((acc, val) => acc + val, 0)
      return +user.balance >= (total - payPoints)
    }
    return false
  }

  /**
   * Verifica se um segmento tem os pontos suficientes para concluir a compra do cart atual
   * @param user
   */
  segmentHasSufficientPoints(user: any): boolean {
    const segment = this.g.get('segment')

    if (user && user.cart && user.cart.length) {
      user.pointsCart = user.pointsCart || []
      const payPoints = user.pointsCart.length ? user.pointsCart.map(i => i.qty).reduce((acc, val) => acc + val, 0) : 0
      const total = user.cart.map(i => i.amtPoints).reduce((acc, val) => acc + val, 0)
      // console.log(+segment.balance >= (total - payPoints))
      return +segment.balance >= (total - payPoints)
    }
    return false
  }

  /**
   * Verifica se todos os produtos do cart são do tipo 'payable'
   * @param user
   */
  hasPayableItems(user: any): boolean {
    let has = true
    for (const item of user.cart)
      if (!item.payable) has = false
    return this.g.type === 'shop' ? true : has
  }

  /**
   * Envia a order para API gravar com recursos de transações do firebase
   * @param orderId
   * @param order
   * @param user
   * @param payment
   */
  saveOrderTransaction(orderId: string, order: any, user: any, payment: IPaymentBase2 = null): Promise<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    const api = use<ApiService>(ApiService)
    return new Promise<any>((resolve, reject) => {
      this.fss.add(`logs`, order)
      this.spin.show()

      if (payment/* && (payment.isMBWay() || payment.isMultibanco() || payment.isCreditCard())*/) {
        order[ 'payment' ] = { ...payment.getConfig(), payload: payment.getPayload() }
        order[ 'paidValue' ] = +payment.getValue()
        order[ 'paidSuccess' ] = payment.getStatus() === PaymentStatus.PAID
        order.status = payment.getStatus() === PaymentStatus.PAID ? OrderStatus.PLACED : OrderStatus.PAYMENT_PENDING
      }

      if (order.type === 'segments') {
        api.post('transactions/placeOrderSegmentsWithTransactions', {
          orderId,
          order: {
            ...order,
            orderDate: order.orderDate.substr(0, 10),
            contactus: `${location.origin}/events/contactus/detail/${user.email}`
          },
          nick: this.g.nick,
          segment: order.segment
        }, true).subscribe(() => resolve(true), err => reject(err.message || err))
      } else {
        api.post('transactions/placeOrderWithTransaction', {
          orderId,
          order: {
            ...order,
            orderDate: order.orderDate.substr(0, 10),
            contactus: `${location.origin}/events/contactus/detail/${user.email}`
          },
          nick: this.g.nick,
          userId: user.id,
          email: user.email.toLowerCase()
        }, true).subscribe(() => resolve(true), err => reject(err.message || err))
      }
    })
  }

  prepareOrder(user: any, payment: IPaymentBase2 = null): any {
    const d = new Date()
    const _program = {
      email: this.g.program.sender,
      sender: this.g.program.sender,
      imgHeader: this.g.program.images[ 'emailHeader' ],
      imgFooter: this.g.program.images[ 'emailFooter' ],
      name: this.g.program.name,
      templateId: this.g.program.emails[ 'orderConfirmation' ],
      type: this.g.program.type,
      url: this.g.program.url
    }

    const cart = Array.from(this.parseProductsPromotions(this.purgeCart(user.cart), user)).map((item: any) => ({
      ...item,
      uniqueCartItemNo: this.generateCartItemId(new Date().getTime())
    }))
    const deliveryPeriods = cart.map((a) => a.deliveryPeriod)

    const order = {
      active: true,
      cart,
      code: PrimaryCode.ORDER,
      email: user.email.toLowerCase(),
      user_email: user.email,
      displayName: user.displayName,
      deliveryDate: `${Math.max(...deliveryPeriods)} dias ùteis`,
      deliveryTo: user.deliveryTo || {},
      mobile: user.mobile || (user.deliveryTo && user.deliveryTo.mobile),
      nick: this.g.nick,
      orderQty: user.orderQty,
      orderEuros: user.orderEuros,
      orderDate: d.toISOString(),
      orderPoints: user.orderPoints,
      paidValue: 0,
      pickupIn: user.pickupIn || {},
      primaryCode: PrimaryCode.ORDER,
      program: _program,
      purchaseOrderDate: '',
      segment: user.segment || '',
      status: OrderStatus.PLACED,
      store: this.g.get('selectedStore') || null,
      storeId: (this.g.get('selectedStore') || { id: null }).id,
      shipDate: '',
      subject: payment ? 'Confirmação de encomeda, pendente de pagamento' : 'Confirmação da encomenda ',
      uid: user.id,
      userCode: user.userCode || '',
      username: user.email,
      updatedAt: d.toISOString(),
      updatedBy: user.email,
      historic: [
        {
          createdAt: new Date().toISOString(),
          timestamp: new Date().getTime(),
          updatedBy: this.g.user.email,
          action: `Criou a encomenda com os items:<br>${cart.map(c => `${c.qty}x ${c.name} - ${c.points} pontos`).join(`<br>`)}`
        }
      ]
    }

    if (order.pickupIn && order.pickupIn.pickupTime) {
      order[ 'deliveryDate' ] = order.pickupIn.pickupTime
    }

    if (payment) {
      _program.templateId = this.g.program.emails[ 'orderPaymentPending' ]
      _program[ 'templateSuccess' ] = this.g.program.emails[ 'orderPaymentSuccess' ]
      _program[ 'templateFail' ] = this.g.program.emails[ 'orderPaymentFail' ]
      if (payment.isMultibanco()) {
        _program[ 'entity' ] = payment.getGatewayData().method.entity
        _program[ 'payref' ] = payment.getGatewayData().method.reference
      }
      if (payment.isCreditCard()) _program[ 'paymentLink' ] = payment.getPaymentLink(this.g.program)

      order[ 'paidValue' ] = payment.getValue()
      order.program = _program
    }

    return JSON.parse(JSON.stringify(order))
  }

  generateId(): string {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    return this.fss.createId
  }

  generateCartItemId(id): string {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    return `${id}-${this.fss.createId}`
  }

  placeOrder(order: any): Observable<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    return new Observable<any>(obs => {
      const transaction: ITransaction = {
        ...order,
        code: TransactionType.ORDER,
        primaryCode: TransactionType.ORDER,
        status: OrderStatus.PLACED,
        historic: [
          {
            createdAt: new Date().toISOString(),
            timestamp: new Date().getTime(),
            updatedBy: this.g.user.email,
            action: `Criou a encomenda`
          }
        ]
      }

      this.fss.add(`transactions`, { ...transaction, active: true }).then(doc => {
        if (order.cart && order.cart instanceof Array) {
          for (const item of order.cart) {
            const cartItem: ICartItem = {
              ...item,
              nick: order.nick,
              transactionId: order.transactionId,
              code: transaction.code,
              status: OrderStatus.PLACED
            }
            this.fss.update(`transactionsItems`, cartItem.uniqueCartItemNo, cartItem)
          }
        }
        obs.next(doc)
        obs.complete()
      })
    })
  }

  /**
   * Faz um parse no cart do user em busca de products com variants e define as variants
   * no name para ser exibido nas tabelas summary
   * @param user
   */
  parseProducsVariants(user: any): any[] {
    const cartLines: any[] = []
    if (user && user.cart && user.cart.length) {
      for (const cartItem of user.cart) {
        const line = { ...cartItem }
        if (cartItem.variants) {
          for (const variant in cartItem.variants) {
            if (cartItem.variants[ variant ] && cartItem.variants[ variant ] instanceof Array) {
              line.name = line.name + ` (${this.transloco.translate(`cart_summary_variant_${variant}`)}: ${cartItem.variants[ variant ].join(', ')})`
              line[ 'selectedVariants' ] = cartItem.variants[ variant ].join(', ')
            }
          }
        }
        cartLines.push(line)
      }
    }
    return cartLines
  }

  /**
   * Faz um parse no cart do user em busca de products que tenham saldos em promoção
   * fazendo assim a devida compensação do saldo
   * @param cart
   * @param user
   */
  parseProductsPromotions(cart: any[], user: any = null) {
    const promotions = user && user.promotions ? { ...user.promotions } : null
    if (cart.length && user && promotions && Object.keys(promotions).length) {
      const cartLines: any[] = []
      for (const cartItem of cart) {
        const line = { ...cartItem }
        if (promotions[ cartItem.id ] && +promotions[ cartItem.id ].balance > 0) {
          const promo = { ...promotions[ cartItem.id ] }
          if (+promo.balance >= +line.qty) {
            line.promotionBalance = +line.qty
            promo.balance -= +line.qty
          } else {
            line.promotionBalance = +line.qty - (+promo.balance)
            promo.balance = 0
          }
        }
        cartLines.push(line)
      }
      return cartLines
    } else return [ ...cart ]
  }

  placeGuestUserCart(guest: any): Promise<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    if (this.g.isBrowser && Cry.getLocal('layoutTemp')) guest = Cry.getLocal('layoutTemp')
    const update = {
      cart: guest.cart,
      addresses: [ ...(guest.addresses || []), ...(this.g.user.addresses || []) ],
      deliveryTo: guest.deliveryTo || null,
      pickupIn: guest.pickupIn || null,
      createdAt: this.g.user.createdAt,
      createdBy: this.g.user.createdBy
    }
    return window ? this.fss.update(`users`, this.g.user.id, update).then(() => Cry.removeLocal('layoutTemp')) : Promise.resolve(true)
    // return window ? this.api.post('db/action', Cry.crypt(JSON.stringify({
    //   action: 'update',
    //   collection: 'users',
    //   id: this.g.user.id,
    //   nick: this.g.nick,
    //   email: this.g.user.email,
    //   data: update
    // }), true)).toPromise().then(() => Cry.removeLocal('layoutTemp')) : Promise.resolve(true)
  }

  verifyItemsChange(older: string, newer: any[]) {
    const old = JSON.parse(older)
    let changed = false
    for (const [ i, o ] of old.entries()) {
      if (o.price !== newer[ i ].price) changed = true
      if (o.stock !== newer[ i ].stock) changed = true
    }
    if (changed)
      this.notify.alert('Atenção', 'Alguns dos items do carrinho tiveram o preço ou stock alterados.<br>Por favor, verifique antes de finalizar a encomenda.').then()
  }

}
